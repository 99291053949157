@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: all 0.2s;
    outline: none;
}

body {
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #222;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.card-enter {
    opacity: 0;
}

.card-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.card-exit {
    opacity: 1;
}

.card-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.custom-dots-left,
.custom-dots-right {
    display: flex;
    gap: 30px;
    padding: 15px 0;
    position: relative;
    &::before {
        content: "";
        width: 2px;
        height: 100%;
        background-color: #333;
        top: 31px;
        left: 4px;
        position: absolute;
    }
    span {
        height: 10px;
        width: 10px;
        border-radius: 100%;
        background-color: #333;
        margin-top: 5px;
    }
    .dots-title {
        margin-top: 16px;
    }

    &:last-child {
        &::before {
            display: none;
        }
    }
    h2,
    p {
        width: calc(100% - 10px);
    }
}

.custom-dots-right {
    &::before {
        right: 4px;
        left: auto;
    }
}

.custom-inner-dots {
    padding-top: 5px;
    padding-bottom: 5px;
}

.product-images-animation {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-15px);
    }
}
@keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-15px);
    }
}

.bg {
    animation: slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #f7f6f2 50%, #ffffff 50%);
    bottom: 0;
    left: -50%;
    opacity: 0.4;
    position: fixed;
    right: -50%;
    top: 0;
    z-index: -1;
}

.bg2 {
    animation-direction: alternate-reverse;
    animation-duration: 6s;
}

.bg3 {
    animation-duration: 7s;
}

@keyframes slide {
    0% {
        transform: translateX(-25%);
    }
    100% {
        transform: translateX(25%);
    }
}

.swiper {
    width: 100%;
    height: 100%;
    // padding: 10px 0 !important;

    * {
        user-select: none;
    }
    .swiper-button-prev,
    .swiper-button-next {
        height: 40px;
        width: 40px;
        background: #333;
        border-radius: 3px;
        opacity: 0.9;
        &::after {
            font-size: 20px;
            font-weight: 600;
            color: #fff;
        }
    }
}

.my-filterDrpShadow {
    filter: drop-shadow(0px 0px 5px #222) !important;
}

.logo-divs {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    .logo-card {
        width: 200px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: slidelogo 3s ease-in-out infinite alternate;
    }
}

.logo-sm-card {
    position: relative;
    margin: 15px 0;
}

.content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 96px;

    transition: transform 0.3s;
    transform-style: preserve-3d;
}

.logo-sm-card:hover .content {
    transform: rotateY(180deg);
    transition: transform 0.5s;
}

.front,
.back {
    position: absolute;
    height: 100%;
    width: 100%;
    color: #03446a;
    text-align: center;
    border-radius: 5px;
    backface-visibility: hidden;
    display: grid;
    place-items: center;
}

.front {
    img {
        padding: 5px;
    }
}

.back {
    transform: rotateY(180deg);
}

@keyframes slidelogo {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}

@media only screen and (max-width: 640px) {
    .custom-dots-left,
    .custom-dots-right {
        gap: 10px;
        padding: 10px 0;
    }
}

:root {
    --color-text: navy;
    --color-bg: papayawhip;
    --color-bg-accent: #ecdcc0;
    --size: clamp(10rem, 1rem + 40vmin, 30rem);
    --gap: calc(var(--size) / 14);
    --duration: 60s;
    --scroll-start: 0;
    --scroll-end: calc(-100% - var(--gap));
}

@media (prefers-color-scheme: dark) {
    :root {
        --color-text: papayawhip;
        --color-bg: navy;
        --color-bg-accent: #2626a0;
    }
}

.marquee {
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
}

.marquee__group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll-x var(--duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
    .marquee__group {
        animation-play-state: paused;
    }
}

.marquee--vertical {
    --mask-direction: to bottom;
}

.marquee--vertical,
.marquee--vertical .marquee__group {
    flex-direction: column;
}

.marquee--vertical .marquee__group {
    animation-name: scroll-y;
}

.marquee--reverse .marquee__group {
    animation-direction: reverse;
    animation-delay: -3s;
}

@keyframes scroll-x {
    from {
        transform: translateX(var(--scroll-start));
    }
    to {
        transform: translateX(var(--scroll-end));
    }
}

@keyframes scroll-y {
    from {
        transform: translateY(var(--scroll-start));
    }
    to {
        transform: translateY(var(--scroll-end));
    }
}

/* Element styles */

.marquee--vertical img {
    aspect-ratio: 1;
    width: calc(var(--size) / 1.5);
    padding: calc(var(--size) / 6);
}

/* Parent wrapper */
.wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    margin: auto;
    max-width: 100vw;
}

.wrapper--vertical {
    flex-direction: row;
    height: 100vh;
}

@keyframes fade {
    to {
        opacity: 0;
        visibility: hidden;
    }
}

.popup-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px 20px;
    background-color: #4caf50;
    color: white;
    border-radius: 5px;
    animation: fadeInAndOut 2s ease;
    z-index: 999;
}

@keyframes fadeInAndOut {
    0%,
    100% {
        opacity: 0;
    }
    10%,
    90% {
        opacity: 1;
    }
}
